import React, {Fragment} from 'react'
import {createContext, FunctionComponent, ReactNode, useContext, useState} from 'react'
import {v4 as uuidv4} from 'uuid'

type Modal = {
  id: string
  content: ReactNode
}

const ModalContext = createContext<{
  modals: Modal[]
  add: (modal: Modal) => void
  remove: (id: Modal['id']) => void
}>({
  modals: [],
  add: () => {},
  remove: () => {},
})

type ModalProviderProps = {
  children?: ReactNode
}

export const ModalProvider: FunctionComponent<ModalProviderProps> = ({children}) => {
  const [modals, setModals] = useState<Modal[]>([])
  const add = (modal: Modal) => {
    setModals(modals => [...modals, modal])
  }
  const remove = (id: string | number) => {
    setModals(modals => {
      const index = modals.findIndex(modal => modal.id === id)
      if (index >= 0) {
        return [...modals.slice(0, index), ...modals.slice(index + 1)]
      } else {
        return modals
      }
    })
  }
  return (
    <ModalContext.Provider value={{modals, add, remove}}>
      {children}
      {modals.length > 0 && (
        <div id="modal-container">
          {modals.map(({id, content}) => (
            <Fragment key={id}>{content}</Fragment>
          ))}
        </div>
      )}
    </ModalContext.Provider>
  )
}

type ModalRendererArgs<T> = {
  resolve: (value: T) => void
  reject: (reason?: any) => void
}
type ModalRenderer<T> = (modalRenderArgs: ModalRendererArgs<T>) => ReactNode
type ModalOpenOptions = {
  signal?: AbortSignal
}

export const useModal = () => {
  const {add, remove} = useContext(ModalContext)
  const open = async <T extends any = void>(render: ModalRenderer<T>, options?: ModalOpenOptions): Promise<T> => {
    const id = uuidv4()
    const signal = options?.signal
    let abort: () => void
    return new Promise<T>((resolve, reject) => {
      abort = () => {
        reject(new Error('Aborted'))
      }
      if (signal?.aborted) {
        abort()
      } else {
        signal?.addEventListener('abort', abort, {once: true})
      }
      add({
        id: id,
        content: render({resolve, reject}),
      })
    }).finally(() => {
      signal?.removeEventListener('abort', abort)
      remove(id)
    })
  }
  return {
    open,
  }
}