import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useBreakpoint } from '../hooks/use-break-point'
import { Autoplay, EffectFade } from 'swiper/modules'
import { SvgLeftAngle, SvgMinus, SvgRightAngle } from './svg'
import { useSwiperClass } from '../hooks/useSwiperClass'
import classNames from 'classnames'

const Outer = styled.div`
  height: 100%;
  position: relative;
  .swiper {
    height: 100%;
  }
  .swiper-wrapper {
    height: 100%;
  }
  .swiper-slide {
    height: 100%;
  }
`

const ImageContainer = styled.div`
  background-color: #d5dbe0;
  height: 100%;
  @media (max-width: 40rem){
    min-height: 40rem;
  }
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const Controls = styled.div`
  position: absolute;
  bottom: 3rem;
  width: 100%;
  display: flex;
  gap: 1rem;
  z-index: 2;
  justify-content: center;
  align-items: center;
`

const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.875rem;
  aspect-ratio: 1 / 1;
  border: 1px solid currentColor;
  border-radius: 100rem;
  cursor: pointer;
  &:first-of-type {
    margin-right: 2rem;
  }
  &:last-child {
    margin-left: 2rem;
  }
  &.disabled {
    opacity: 0.25;
    pointer-events: none;
  }
  svg {
    display: block;
    height: 0.5rem;
    width: auto;
  }
`

const Num = styled.div`
  font-size: 0.6875rem;
  line-height: 5.818181818181818;
  font-family: var(--serif-font);
  transform: translateY(0.2em);
`

const Hyphen = styled.div`
  svg {
    display: block;
    height: 0.5rem;
    width: auto;
  }
`

type ProductSliderProps = {
  slides: {
    img: string
    mobileImg?: string
  }[]
}
export const ProductSlider: FunctionComponent<ProductSliderProps> = ({slides}) => {
  const isMobile = useBreakpoint('40rem')
  const {ref, activeIndex, slideCount, slideNext, slidePrev, hasNext, hasPrev} = useSwiperClass()
  return <Outer>
    <Swiper
      effect="fade"
      modules={[
        EffectFade,
        Autoplay
      ]}
      autoplay={{
        delay: 4000
      }}
      speed={1000}
      loop
      onSwiper={ref}
    >
      {slides.map((slide, i) => {
        return <SwiperSlide key={i}>
          <ImageContainer>
            <img src={isMobile && slide.mobileImg ? slide.mobileImg : slide.img} />
          </ImageContainer>
        </SwiperSlide>
      })}
    </Swiper>
    <Controls>
      <Button className={classNames({disabled: !hasPrev})} onClick={() => slidePrev()}>
        <SvgLeftAngle size={8} />
      </Button>
      <Num>
        {(activeIndex + 1).toString().padStart(2, '0')}
      </Num>
      <Hyphen>
        <SvgMinus size={12} />
      </Hyphen>
      <Num>
        {slideCount.toString().padStart(2, '0')}
      </Num>
      <Button className={classNames({disabled: !hasNext})} onClick={() => slideNext()}>
        <SvgRightAngle size={8} />
      </Button>
    </Controls>
  </Outer>
}