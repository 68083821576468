import React, { ComponentProps, FunctionComponent } from 'react'
import styled from 'styled-components'
import { SvgRightAngle } from './svg'
import { Animate } from './animate'

const Outer = styled.a`
  display: flex;
  gap: 1rem;
  padding: 0.75rem 1.75rem 0.75rem 1.5rem;
  border: 1px solid currentColor;
  width: fit-content;
  border-radius: 0.1875rem;
  transition: background-color 0.4s, color 0.4s, border 0.4s;

  &:hover {
    background-color: var(--text-color);
    color: white;
    border-color: var(--text-color);
  }
  @media (max-width: 40rem){
    padding: 0.6875rem 1.25rem;
    gap: 0.75rem;
  }
`

const Label = styled.div`
  font-size: 0.9375rem;
  letter-spacing: 0.1em;
  font-family: var(--serif-font);
  opacity: 0;
  transform: translateX(-0.5rem);
  .animate & {
    opacity: 1;
    transform: none;
    transition: opacity 0.4s 0.2s, transform 0.8s 0.2s;
  }
  @media (max-width: 40rem){
    font-size: 0.75rem;
    letter-spacing: 0.1em;
  }
`

const AngleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: -0.75rem;
  @media (max-width: 40rem){
    margin-right: -0.5rem;
  }
  svg {
    display: block;
    height: 0.5rem;
    width: auto;
    transform: translateX(-0.5rem);
    opacity: 0;
    .animate & {
      transition: transform 0.8s 0.4s, opacity 0.4s 0.4s;
      transform: none;
      opacity: 1;
    }
  }
`

type ButtonProps = {
} & ComponentProps<'a'>
export const Button: FunctionComponent<ButtonProps> = (props) => {
  const {children, ...anchorProps} = props
  return <Animate>
    <Outer {...anchorProps}>
      <Label>
        {children}
      </Label>
      <AngleContainer>
        <SvgRightAngle size={8}></SvgRightAngle>
      </AngleContainer>
    </Outer>
  </Animate>
}