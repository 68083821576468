import React, { ComponentProps } from 'react';
import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { useSettings } from '../hooks/use-settings'

const Picture = styled.picture`
  display: contents;
`

const Img = styled.img`
  display: block;
`

export type ImageProps = {
  src: string
} & ComponentProps<'img'>
export const Image: FunctionComponent<ImageProps> = (props) => {
  return <Picture>
    <Img {...props} src={`${props.src.replace(/\.[^.]*$/, '')}.webp`}/>
  </Picture>
}
export const AssetImage: FunctionComponent<ImageProps> = (props) => {
  const {themeFileRootPath} = useSettings()
  return <Picture>
    <Img {...props} src={`${themeFileRootPath}/assets/bundle/images/${props.src.replace(/\.[^.]*$/, '')}.webp`}/>
  </Picture>
}