import { css } from 'styled-components'

export const pageMaxWidth = css`
  max-width: var(--page-max-width);
  padding-left: var(--page-margin);
  padding-right: var(--page-margin);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
`

export const pageMaxWidthWithPadding = css`
  max-width: var(--page-max-width);
  padding-left: calc(var(--page-margin) + var(--page-inside-margin));
  padding-right: calc(var(--page-margin) + var(--page-inside-margin));
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  @media (max-width: 40rem){
    padding-left: var(--page-margin);
    padding-right: var(--page-margin);
  }
`


export const rightSideMaxWidth = css`
  --side-margin: calc((100% - (min(var(--page-max-width), 100%) - var(--page-margin) * 2)) / 2);
  box-sizing: border-box;
  margin-left: var(--side-margin);
  padding-left: var(--page-inside-margin);
  padding-right: calc(var(--page-margin) + var(--side-margin));
  @media (max-width: 40rem){
    ${pageMaxWidth}
  }
`