import React, { FunctionComponent, ReactNode } from 'react'
import styled, { keyframes } from 'styled-components'
import { SvgCross } from './svg'

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const Outer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: stretch;
  z-index: 100;
  animation: ${fadeIn} 0.4s both;
`

const blur = keyframes`
  from {
    backdrop-filter: none;
    background-color: transparent;
  }
  to {
    background-color: #bababa44;
    backdrop-filter: blur(0.5rem);
  }
`

const Bg = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  animation: ${blur} 0.4s both;
`

const Inner = styled.div`
  position: absolute;
  width: max-content;
  height: max-content;
  --margin: 4rem;
  margin: var(--margin);
  top: calc(50% - var(--margin));
  left: calc(50% - var(--margin));
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 4rem;
  max-width: min(calc(100% - var(--margin) * 2), 40rem);
  max-height: calc(100% - var(--margin) * 2);
  overflow-y: scroll;
`

const Text1 = styled.div`
  line-height: 1.5;
  font-size: 0.8125rem;
`

const CloseButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 1rem;
  cursor: pointer;
  svg {
    display: block;
    height: 1.25rem;
    width: auto;
  }
`

type Props = {
  onClose?: (() => void) | undefined
  children?: ReactNode
}
export const Popup: FunctionComponent<Props> = ({onClose, children}) => {
  return <Outer>
    <Bg onClick={onClose}/>
    <Inner>
      <Text1>
        {children}
      </Text1>
      <CloseButton onClick={onClose}>
        <SvgCross size={20} />
      </CloseButton>
    </Inner>
  </Outer>
}