import * as React from "react";
import type { SVGProps } from "react";
const SvgPerson = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={26}
    height={26}
    fill="none"
    viewBox="0 0 26 26"
    {...props}
  >
    <path
      fill="currentcolor"
      d="M13 0C5.83 0 0 5.828 0 12.996c0 2.723.842 5.248 2.281 7.339.145.208.3.407.462.615C5.124 24.01 8.836 26 13 26s7.876-1.982 10.257-5.05c.154-.199.317-.398.462-.615A12.9 12.9 0 0 0 26 12.995C26 5.829 20.17 0 13 0m0 25.358c-4.12 0-7.758-2.028-10.013-5.132 1.313-3.104 5.17-5.375 9.741-5.466.09 0 .181.027.272.027.09 0 .181-.018.272-.027 4.571.09 8.428 2.362 9.74 5.466C20.768 23.33 17.12 25.358 13 25.358m0-11.24h-.272c-2-.145-3.585-1.792-3.585-3.828 0-2.037 1.73-3.856 3.857-3.856a3.86 3.86 0 0 1 3.857 3.856c0 2.126-1.585 3.683-3.585 3.828zm10.42 5.511c-1.34-2.742-4.5-4.806-8.365-5.358 1.44-.75 2.435-2.235 2.435-3.963 0-2.48-2.01-4.489-4.49-4.489s-4.49 2.01-4.49 4.489c0 1.737.996 3.221 2.435 3.964-3.866.551-7.025 2.615-8.365 5.357a12.3 12.3 0 0 1-1.946-6.634C.634 6.182 6.184.633 13 .633c6.817 0 12.366 5.548 12.366 12.363 0 2.443-.724 4.714-1.946 6.633"
    />
  </svg>
);
export default SvgPerson;
