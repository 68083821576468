import React, { FunctionComponent, ReactNode } from 'react'
import styled from 'styled-components'
import { Animate } from './animate'
import { CroppedText } from './cropped-text'

const Outer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 3.75rem;
  @media (max-width: 40rem){
    margin-bottom: 3.125rem;
  }
  &::after {
    content: '';
    display: block;
    height: 1px;
    background-color: currentColor;
    opacity: 0;
    max-width: 20rem;
    width: 100%;
    .animate & {
      opacity: 1;
      max-width: 1rem;
      transition: max-width 0.8s, opacity 0.4s;
    }
  }
`

const Text = styled(CroppedText)`
  letter-spacing: 0.1em;
  font-family: var(--serif-font);
  @media (max-width: 40rem){
    font-size: 0.875rem;
    line-height: 27.142857142857142;
    letter-spacing: 0.1em;
  }
`

type SectionHeadProps = {
  children?: ReactNode
}
export const SectionHead: FunctionComponent<SectionHeadProps> = ({children}) => {
  return <Animate>
    <Outer>
      <Text>
        {children}
      </Text>
    </Outer>
  </Animate>
}