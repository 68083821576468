import React, { FunctionComponent, ReactNode, useState } from 'react'
import styled from 'styled-components'
import classNames from 'classnames'
import { SvgMinus, SvgPlus } from './svg'

const Outer = styled.div`
  display: flex;
  flex-direction: column;
`

const RowOuter = styled.div`
  transition: padding 0.4s;
`

const Label = styled.div`
  font-size: 1rem;
  line-height: 1.5;
  font-family: var(--serif-font);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3.25rem 0;
  border-top: 1px solid #EEEEEE;
  transition: border-bottom-color 0.4s;
  cursor: pointer;
  ${RowOuter}.open & {
    border-bottom-color: transparent;
  }
`

const Icon = styled.div`
  svg {
    display: block;
    height: 0.875rem;
    width: auto;
  }
`

const Value = styled.div`
  display: grid;
  grid-template-rows: 0fr;
  overflow: hidden;
  transition: grid-template-rows 0.4s, padding 0.4s;
  ${RowOuter}.open & {
    grid-template-rows: 1fr;
    padding-bottom: 3.25rem;
  }
`

const ValueInner = styled.div`
  overflow: hidden;
  font-size: 0.875rem;
  line-height: 1.5;
`

type DetailTableItem = {
  label: ReactNode,
  value: ReactNode
}
type DetailTableProps = {
  items: DetailTableItem[]
}
export const DetailTable: FunctionComponent<DetailTableProps> = ({items}) => {
  return <Outer>
    {
      items.map((item, i) => {
        return <Row key={i} item={item} />
      })
    }
  </Outer>
}

type RowProps = {
  item: DetailTableItem
}
const Row: FunctionComponent<RowProps> = ({item}) => {
  const [open, setOpen] = useState(true)
  return <RowOuter className={classNames({open})}>
    <Label onClick={() => setOpen(v => !v)}>
      {item.label}
      <Icon>
        {open ? <SvgMinus size={14} /> : <SvgPlus size={14} />}
      </Icon>
    </Label>
    <Value>
      <ValueInner>
        {item.value}
      </ValueInner>
    </Value>
  </RowOuter>
}