type InformationArticle = {
  img: string
  title: string
  content: string
  slug: string
  publishedAt: string
}
type Settings = {
  themeFileRootPath: string,
  productId?: number
  topics?: InformationArticle[]
  news: InformationArticle[]
}
export const useSettings = (): Settings => {
  return Object.assign({}, ...Array.from(document.querySelectorAll('.settings')).map(el => {
    if(el.textContent){
      return JSON.parse(el.textContent)
    } else {
      return {}
    }
  }))
}