import React, { ComponentPropsWithRef, FunctionComponent, ReactNode, useEffect, useRef, useState } from 'react'
import { styled } from 'styled-components'

const Inner = styled.div`
  display: flex;
  flex-direction: column;
`

const normalize = (value: string) => Number(value.replace(/px$/, ''))

type CroppedTextProps = {
  children?: ReactNode
} & ComponentPropsWithRef<'div'>
export const CroppedText: FunctionComponent<CroppedTextProps> = React.forwardRef<HTMLDivElement, CroppedTextProps>((props, ref) => {
  const {children, dangerouslySetInnerHTML, ...outerProps} = props
  const innerRef = useRef<HTMLDivElement>(null)
  const [lineHeight, setLineHeight] = useState(0)
  const [fontSize, setFontSize] = useState(0)
  const offset = (lineHeight - fontSize) / 2 * -1
  useEffect(() => {
    const outer = innerRef.current
    if(outer){
      const listener = () => {
        const {lineHeight, fontSize} = window.getComputedStyle(outer)
        setLineHeight(normalize(lineHeight))
        setFontSize(normalize(fontSize))
      }
      window.addEventListener('resize', listener)
      listener()
      return () => {
        window.removeEventListener('resize', listener)
      }
    }
  }, [])
  return <div ref={ref} {...outerProps}>
    <Inner>
      <div ref={innerRef} style={{marginTop: offset, marginBottom: offset}} dangerouslySetInnerHTML={dangerouslySetInnerHTML}>
        {children}
      </div>
    </Inner>
  </div>
})