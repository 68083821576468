import * as React from "react";
import type { SVGProps } from "react";
const SvgBag = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={26}
    fill="none"
    viewBox="0 0 25 26"
    {...props}
  >
    <path
      fill="currentcolor"
      fillRule="evenodd"
      d="M22.086 5.74c.544 0 .986.394 1.017.923l.001.022v.002l1.228 17.682v.016a.95.95 0 0 1-.285.687c-.19.189-.457.297-.733.297H1.686c-.276 0-.542-.108-.733-.297a.93.93 0 0 1-.285-.685L1.896 6.669c.019-.522.466-.928 1.018-.928h4.77v2.28c-.43.135-.743.504-.743.955 0 .162.04.315.11.45.176.339.544.568.965.568s.786-.232.963-.566a.96.96 0 0 0 .113-.452c0-.45-.314-.82-.742-.955V5.74h8.9v2.28c-.428.135-.742.504-.742.955 0 .162.04.315.11.45.176.339.544.568.965.568s.786-.232.963-.566a.96.96 0 0 0 .113-.452c0-.45-.314-.82-.743-.955V5.74zM17.24 5.1v-.306a4 4 0 0 0-.283-1.42C16.325 1.785 14.7.645 12.805.64h-.01c-1.9 0-3.531 1.137-4.164 2.735a4 4 0 0 0-.281 1.42v.054h-.01V5.1zm-9.558-.306h.01a4.6 4.6 0 0 1 .332-1.672C8.757 1.301 10.615.004 12.795 0h.01c2.526 0 4.621 1.736 5.037 4.015q.07.38.074.78V5.1h4.17c.904 0 1.656.685 1.685 1.54l1.228 17.7v.005c.018.456-.168.872-.477 1.172a1.73 1.73 0 0 1-1.208.482H1.686c-.472 0-.9-.186-1.206-.484a1.54 1.54 0 0 1-.479-1.172v-.002l1.228-17.7.001-.016c.047-.858.785-1.525 1.684-1.525h4.77z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgBag;
